import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import productosReducer from "./ProductoDucks";
import clienteReducer from "./ClienteDucks";
import vendedorReducer from "./VendedorDucks";
import licenciaReducer from "./LicenciaDucks";
import clientesReducer from "./ClientesAllDucks";
import prospectosReducer from "./Prospectos";
import clientesConLincenciaReducer from "./ClientesConLicenciaDuck";
import formaPagosReducer from "./FormaPagosDucks";
import extrasReducer from "./ExtrasDucks";
import deliveryReducer from "./DeliveryDucks";
import compradorReducer from "./CompradorDucks";

const rootReducer = combineReducers({
    productos: productosReducer,
    cliente: clienteReducer,
    vendedores: vendedorReducer,
    licencias: licenciaReducer,
    clientes: clientesReducer,
    prospectos: prospectosReducer,
    clientesLicencia: clientesConLincenciaReducer,
    formaPagos: formaPagosReducer,
    delivery: deliveryReducer,
    extras: extrasReducer,
    //comprador: compradorReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    );
    //leerUsuarioActivoAccion()(store.dispatch)

    return store;
}