//Constantes

const extraIni = {
  id: "",
  nombre: "",
  precio: 0
};
const dataInicial = {
  loading: true,
  count: 0,
  next: null,
  previous: null,
  results: []
};

const compradorIni = {
  pickup: true,
  nombre: null,
  telefono: null,
  direccion: null,
  comentarios: null,
  docidentidad: null,
  instagram: "",
  formaPago: {
    cod: "",
    tipo: null,
    nombreBanco: "",
    cuenta: "",
    titular: "",
    cedula: "",
  },
};

//types

const LOADING = "LOADING";
const OBTENER_COMPRADOR = "OBTENER_COMPRADOR";
const COMPRADOR_ERROR = "COMPRADOR_ERROR";


//Reducers

export default function compradorReducer(state = compradorIni, action) {
  switch (action.type) {
    
    case OBTENER_COMPRADOR:
      return { action,  results: action.payload };
    case COMPRADOR_ERROR:
      return { ...state };
    default:
      return state;
  }
}

//Acciones

export const obtenerCompradorAccion = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
   
    const val = compradorIni;   

    dispatch({
      type: OBTENER_COMPRADOR,
      payload: val,
    });
  } catch (error) {
    dispatch({
      type: COMPRADOR_ERROR,
    });
  }
};

export const actualizarCompradorAccion = (comprador) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
   
       
 console.log("Recibiendo Comprador: ");
 console.log(comprador);
    dispatch({
      type: OBTENER_COMPRADOR,
      payload: comprador,
    });
  } catch (error) {
    dispatch({
      type: COMPRADOR_ERROR,
    });
  }
};

