import React from "react";
import { createContext, useContext, useState } from "react";

export const CompradorContext = createContext();

const InitialStateComprador = {
  ...(JSON.parse(localStorage.getItem("Whatspidelo")) || {
    pickup: true,
    nombre: null,
    telefono: null,
    direccion: null,
    comentarios: null,
    docidentidad: null,
    instagram: "",
    formaPago: {},
  }),
  formaPago: {
    cod: null,
    tipo: null,
    nombreBanco: null,
    cuenta: null,
    titular: null,
    cedula: null,
  },
};


const CompradorProvider = ({ children }) => {
  const [comprador, setComprador] = useState(InitialStateComprador);

  return (
    <CompradorContext.Provider value={{ comprador, setComprador }}>
      {children}
    </CompradorContext.Provider>
  );
};

export default CompradorProvider;

export const useCompradorContext = () => useContext(CompradorContext);
